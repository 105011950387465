import React from "react";
import {useDispatch} from "react-redux"
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getPlan } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import style from "./GestionPlanes.module.css"
import SearchBarCurso from "../SearchBarCurso/SearchBarCurso";
import NavBar from "../NavBar/NavBar"
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";

const GestionPlanes = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const planes = useSelector((state) => state.plan)

    useEffect(()=>{
        dispatch(getPlan()) 
    },[dispatch])

    return(
        <div className={style.grid}>
            <div>
                <NavBar />
            </div>

            <div>
                <div>
                    <NavBarSuperior/>
                </div>

                <div className={style.container}>
                <h2>Ver Planes de Estudio</h2>
           
            <br></br>

            <div className={style.tablacontainer}>
                <table className={style.tabla} >
                    <thead className={style.encabezado}>
                        <tr>
                            <th className={style.tabla}>Id Plan</th>
                            <th className={style.tabla}>Nombre</th>
                            <th className={style.tabla}>Descripción</th>
                            <th className={style.tabla}>Id Departamento Académico</th>
                            
                            


                        </tr>
                    </thead>
                    <tbody>
                        {planes.map(planes => (
                            <tr key={planes.id_asignatura}>
                                <td className={style.encabezado}>{planes.id_plan}</td>
                                <td className={style.encabezado}>{planes.nombre}</td>
                                <td className={style.encabezado}>{planes.descripcion}</td>
                                <td className={style.encabezado}>{planes.id_departamento}</td>
                                

                                {/* <td className={style.encabezado}><Link to={`/updatecurso/${cursos.id_asignatura}`} >Modificar</Link></td> */}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
                </div>

            </div>


        </div>
    )
}

export default GestionPlanes;